.horizontal-label-top {
  align-self: flex-start;
  font-style: italic;
  @media (max-width: 830px) {
    margin-left: 1.5%;
  }
}

.horizontal-label-bottom {
  align-self: flex-end;
  font-style: italic;
  @media (max-width: 830px) {
    margin-right: 10%;
  }
}
